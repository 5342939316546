



























































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";

import "pdfjs-dist/build/pdf.worker.entry";

@Component({
  components: {
    pdf: () => import("pdfvuer")
  }
})
export default class PdfViewer extends Vue {
  @Prop({ required: true })
  src!: String;

  @Prop({ default: undefined })
  name!: String;

  @Prop({ default: true })
  showTitleBar!: Boolean;

  //   @PropSync("page", { default: 1 })
  //   syncedPage!: Number;

  page = 1;

  get documentName() {
    if (this.name) {
      return this.name;
    }
    const name = this.src.split("/");
    return name[name.length - 1];
  }

  totalPages = 1;
  scale = 100;

  mounted() {
    this.totalPages = 10;
  }
}
