




















































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import PdfViewer from "@/components/UIComponents/PdfViewer.vue";

@Component({
  components: {
    PdfViewer
  }
})
export default class UploadImage extends Vue {
  @PropSync("value", { default: undefined })
  syncedValue!: File | String | undefined;

  get syncedFileList() {
    const files: ({ name: string; url: string } | File)[] = [];
    if (this.syncedValue) {
      if (typeof this.syncedValue == "string") {
        files.push({
          name: this.syncedValue.split("/")[this.syncedValue.split("/").length - 1],
          url: this.syncedValue
        });
      } else if (this.syncedValue instanceof File) {
        files.push({
          name: this.syncedValue.name,
          url: window.URL.createObjectURL(this.syncedValue)
        });
      }
    }
    return files;
  }

  get listType() {
    if (this.$attrs["list-type"]) {
      return this.$attrs["list-type"];
    }
    return "text";
  }

  get disabled() {
    if (this.$attrs.disabled) {
      return this.$attrs.disabled;
    }
    return false;
  }

  @Prop({ default: false })
  previewImage!: boolean;

  @Prop({ default: "" })
  vValidate!: string;

  @Prop({ default: "medium" })
  buttonSize!: string;

  @Prop({ default: "primary" })
  buttonType!: string;

  @Prop({ required: true })
  name!: string;

  @Prop({ default: false, type: Boolean })
  drag!: boolean;

  @Prop({ default: undefined, type: String })
  tip!: string;

  image: any = { name: "", url: "" };
  pdf: any = { name: "", url: "" };
  imageDialog: boolean = false;

  @Watch("syncedValue", { immediate: true })
  onValueChange(newValue: any) {
    if (newValue && typeof newValue == "string" && newValue.endsWith(".pdf")) {
      this.pdf = { name: newValue.split("/"), url: newValue };
    }
  }

  handleAfterUpload(file: any, fileList: any[]) {
    if (file.raw.type.includes("pdf")) {
      this.pdf = file;
      if (this.pdf != undefined) {
      }
    }
    this.syncedValue = file.raw;
    this.$emit("input", file.raw);
  }

  handleRemove(file: any, fileList: any[]) {
    this.syncedValue = undefined;
    this.image = { name: "", url: "" };
    this.pdf = { name: "", url: "" };
    this.$emit("input", undefined);
  }

  handlePreview(file: any) {
    if (this.previewImage) {
      if (file.name.endsWith(".pdf")) {
        this.pdf = file;
        if (this.pdf != undefined) {
          this.imageDialog = true;
        }
      } else {
        this.image = file;
        console.log(this.image);
        if (this.image != undefined) {
          this.imageDialog = true;
        }
      }
    }
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }
}
